'use client'
import React from 'react'
import { composeRenderProps, Button as RACButton } from 'react-aria-components'

import type { ButtonProps } from './types'
import { tv } from '../utils'

const button = tv({
  base: 'csg-tw-button rounded-sm px-2 h-8 transition-shadow focus:border-input-focus focus:shadow-std focus:shadow-primary font-normal leading-normal',
  variants: {
    variant: {
      primary:
        'cursor-pointer bg-primary text-over-primary hover:opacity-40 disabled:bg-neutral',
      secondary:
        'cursor-pointer bg-over-primary text-input-text border border-solid border-input-border hover:opacity-40 disabled:bg-neutral',
      text: 'cursor-pointer bg-over-primary text-input-text hover:bg-neutral-lighter',
      icon: 'cursor-pointer bg-over-primary text-input-text border-0 disabled:opacity-40'
    },
    isDisabled: {
      true: 'opacity-40 cursor-not-allowed filter grayscale'
    }
  },
  defaultVariants: {
    variant: 'primary'
  }
})
//csg-tw-button px-2 h-8 transition-shadow inline-flex items-center justify-center relative rounded-sm font-inherit

export function Button(props: ButtonProps) {
  return (
    <RACButton
      {...props}
      className={composeRenderProps(props.className, (className, renderProps) =>
        button({ ...renderProps, variant: props.variant, className })
      )}
    />
  )
}
